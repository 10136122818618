import AceEditor from "react-ace";

import "ace-builds/src-noconflict/keybinding-vim";
import "ace-builds/src-noconflict/theme-gruvbox";
import logo from "./logo.png";
import "./App.css";

function App() {
  return (
    <div className="App">
      <div className="container">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <h2 className="Name">Jordan Haine</h2>
          <h6 style={{ marginTop: "-10px" }}>Staff Security Engineer</h6>
          <div style={{ marginTop: "-25px", marginBottom: "-25px" }}>
            {editor()}
          </div>
          <div className="Socials">
            <a href="https://github.com/Gee19" target="_blank" rel="noreferrer">
              <i
                onClick={() => console.info("test")}
                className="fa fa-github fa-2x"
                aria-hidden="true"
              ></i>
            </a>
            <a
              href="https://www.linkedin.com/in/jordan-haine-6bba505a/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-linkedin fa-2x" aria-hidden="true"></i>
            </a>
            <a href="/resume.pdf?v=2" target="_blank" rel="noreferrer">
              <i className="fa fa-file-pdf-o fa-2x" aria-hidden="true"></i>
            </a>
          </div>
        </header>
      </div>
    </div>
  );
}

const content = `
Versatile software developer experienced in Python, Bash, and Javascript.

Proficient in Linux, MacOSX and Windows environments.

Experience designing, implementing and securing complex software systems.

Interests:
• Programming & Security
• Malware Analysis
• Decentralized Systems
• Reverse Engineering
• vim

                           Hack the planet
                  1C2d9VtrKVfnEu3fyYUkP8cq2vDFddArSt


                     Github | LinkedIn | Resume`;

const editor = () => {
  return (
    <div className="editor">
      <AceEditor
        mode="text"
        theme="gruvbox"
        keyboardHandler="vim"
        name="editor1"
        width="750px"
        height="400px"
        fontSize="17px"
        value={content}
        wrapEnabled={true}
        editorProps={{ $blockScrolling: true }}
      />
    </div>
  );
};

export default App;
